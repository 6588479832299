import React from 'react'
import { withTranslation } from 'react-i18next';

import {Footer, SignupDivider, 
//HeroPlain, Feature, VideoSection, Video, 
Hinnasto, HeroPricing} from '../../components';
import {WebLayout} from '../../layouts';

const FeaturesPage = ({t}) => (
  <WebLayout alwaysSticky={true}
    title="Hinnasto - Valitse yhden käyttäjän versio tai ota työkalu käyttöön koko tiimillesi. - Duuers"
    meta={[
      {name: 'description', content: 'Laadukas tarjoustyökalu - Duuers tarjoaa tarjoustyökaluohjelmiston pienille yrityksille. Nopea ja helppo käyttää. Rekisteröidy ilmaiseksi nyt!'},
      {name: 'keywords', content: ''},
    ]}
    >
    <HeroPricing
      link={[{
          rel: "alternate", href: "https://www.duuers.com/fi/features", hreflang: "fi"
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/features', hreflang: 'en'
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/features', hreflang: 'x-default'
      }]}
      >
     {/* <h1 className="font_second semibold font40 center">Hinnasto</h1>
      <h2 className="font20 padding_top20 opacity9 text center">
        Valitse yhden käyttäjän versio tai ota työkalu käyttöön koko tiimillesi.
      </h2> */}
      <h1 className="font_second semibold font40 center">{t('component.hinnasto.maintitle')}</h1>
      <h2 className="font20 padding_top20 opacity9 text center">{t('component.hinnasto.mainsubtitle')}</h2>
      <Hinnasto/>
    </HeroPricing>
    <section className="margin_auto max_width600 bg_white90 padding_top20 padding_bottom40 text-center">
    					<div className="font18 light dark_blue hyphens text top30">{t('pricelist.needhelp.text')}</div>
    					<div className="font18 light dark_blue hyphens text top30"><i className="fa fa-phone blue"></i>{t('component.featureTestimonial.choose.phone')}</div>
    					<div className="top20"><a className="blue" href={t('component.featureTestimonial.choose.linkkiurl')}>{t('component.featureTestimonial.choose.linkkiteksti')}</a></div>
		</section>	
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)

export default withTranslation()(FeaturesPage)
